@import "loader.css";
@import "base.css";
@import 'simplebar-react/dist/simplebar.min.css';

* {
    margin: 0;
    padding: 0;
}

.MuiTextField-root[hidden] {
  display: none !important;
}

.height-min {
  /*min-height: 100vh;*/
}

.with_ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  text-overflow: ellipsis;
}

.sortable-chosen {
  background-color: white;
}

.required-border .ql-toolbar {
    border-top: solid 1px red !important;
    border-bottom: solid 1px red !important;
    border-left: solid 1px red !important;
    border-right: solid 1px red !important;
}

.required-border .ql-container {
    border-bottom: solid 1px red !important;
    border-left: solid 1px red !important;
    border-right: solid 1px red !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animate_rotating {
    animation: rotate 2s linear infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5); /* Adjust the scale value to control the intensity of the pulse */
    }
    100% {
        transform: scale(1);
    }
}

@keyframes pulse-low {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Adjust the scale value to control the intensity of the pulse */
    }
    100% {
        transform: scale(1);
    }
}

.animate_pulsing {
    animation: pulse 2s infinite;
}

.animate_pulsing_low {
    animation: pulse-low 2s infinite;
}

@media print {
    @page {
        size: A4;
        /*width: 270mm;*/
        margin: 15mm 10mm;
    }

    @page :right {
        @bottom-right {
            content: counter(page);
        }
    }

    @page :left {
        @bottom-left {
            content: counter(page);
        }
    }

    html {
        padding: 0 !important;
        margin: 0 !important;
    }

    body {
        /*width: 270mm;*/

        /*margin: 0 !important;*/
        /*padding: 0 !important;*/

        /*!*font: 13pt "Georgia", "Times New Roman", Times, serif;*!*/
        /*font: 13pt "Plus Jakarta Sans", "Be Vietnam", sans-serif;*/

        /*line-height: 1.3;*/
        /*color: black !important;*/

        font-family: Arial, sans-serif;
        font-size: 12pt;
        line-height: 1.2;
        color: #000;
        background: #fff;
        /*margin: 1in;*/
        padding: 0 !important;
        margin: 0 !important;
    }

    .mainContent {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .standard-sidebar {
        display: none !important;
    }

    * {
        transition: none !important
    }

    /*img {
        display: none; !* Hide images *!
        !* Or convert to grayscale *!
        filter: grayscale(100%);
    }*/

    a[href]:not([href="#"]):after {
        content: " (" attr(href) ")";
    }

    table {
        page-break-inside: avoid;
    }

    .standard-sidebar {
        display: none !important;
    }

    .MuiToolbar-root {
        padding-left: 0 !important;

        .app-logo {
            padding-left: 0 !important;
            margin-left: 0 !important;
        }
    }

    .MuiListItemText-root {
        span {
            font-size: 14pt;
        }
    }

    .MuiCardHeader-content {
        span {
            font-size: 14pt;
        }
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }

    [role="tooltip"] {
        display: none;
    }
}

@media screen {
    .printing {
        .mainContent {
            width: 100% !important;
            margin-left: 0 !important;
        }

        .standard-sidebar {
            display: none !important;
        }

        * {
            /*-webkit-transition: none !important;*/
            transition: none !important
        }

    }

    .printing-download {
        .MuiChip-label {


        }

        button {
            display: none;
        }
    }
}

@keyframes slideInOut {
    0% {
        transform: translateX(-100%);
    }
    5% {
        transform: translateX(0);
    }
    95% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes slideUpDown {
    0% {
        transform: translateY(-200%);
    }
    5% {
        transform: translateY(0);
    }
    95% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(200%);
        /*transform: translateY(-100%);*/
    }
}

.AppMessageView-success {
    background-color: #05c1a1;
    border-color: #c3e6cb;
    color: #FFF;
}

.AppMessageView-warning {
    background-color: #9a4dff;
    border-color: #ffeeba;
    color: white;
}
.AppMessageView-violet {
    background-color: #9a4dff;
    border-color: #ffeeba;
    color: white;
}

.AppMessageView-iconVariant {
    opacity: 0.9;
    margin-right: 10px;
}

.AppConfirmDialog-success {
    color: #05c1a1;
}

.AppConfirmDialog-warning {
    color: #9a4dff;
}

.AppConfirmDialog-violet {
    color: #9a4dff;
}

.textContent {
    color: #000;
    font-size: 16px;

    p {
        font-size: 16px;
    }

    ul, ol {
        padding-left: 1.5em;
    }

    ol li:not(.ql-direction-rtl), ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }

    ol > li, ul > li {
        list-style-type: none;
    }

    ol,ul {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;
    }

    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }

    ol li:before {
        content: counter(list-0, decimal) '. ';
    }

    ul > li::before {
        content: '\2022';
    }

    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }
}

.quill .ql-snow .ql-editor {
    font-family: Plus Jakarta Sans, Be Vietnam, sans-serif;
    color: #000;

    p {
        font-size: 16px;
    }

    h1 {
        font-size: 22px;
        font-weight: 600;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
    }
}

a.adviceLink {
    color: #FFF;
    text-decoration-color: #FFF;
}

.confirm-dialog a.adviceLink {
    color: inherit;
    text-decoration-color: inherit;
}
